import { useParams } from "react-router-dom";
import { series } from "../data";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";

export default function SeriesPage() {
  const params = useParams();
  const serie = series.find(serie => serie.id === params.id);
  console.log(serie);

  return (
    <div className="flex flex-col gap-6">
      <div onClick={() => window.history.back()} className="text-slate-600 font-semibold bg-slate-100 rounded px-2 py-1 w-fit cursor-pointer flex gap-1"><ArrowLeftIcon className="h-6 w-6" />Terug</div>
      <div className='flex py-2 text-2xl font-semibold  text-gray-500'>{serie?.title}</div>
      <div className="text-slate-600">{serie?.description}</div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 px-4">
        {
          serie?.images.map(image => <div>
            <img src={`/${serie?.id}/${image}.JPG`} alt={image} className="w-full h-full object-cover" />
          </div>)
        }
      </div>
    </div>
  )
}