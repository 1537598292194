export default function AboutPage() {
  return (
    <div className="flex flex-col lg:flex-row-reverse justify-between gap-4 h-full overflow-auto">  
      <div className="w-full max-w-[500px] flex flex-col justify-center pr-4">
        <img src="/Ans.jpg" alt="Ans" className="w-full" />
      </div>    
      <div className="flex-1 font-medium text-gray-500" contentEditable>
        <p className="mb-2">Hallo! Mijn naam is Ans Christiaens.</p>
        <p className="mb-2">Ik woon en werk afwisselend in ’s-Hertogenbosch en in Griekenland op Evia.</p>
        <p className="mb-2">Na mijn werkzame leven ben ik in 2012 gestart met de Academie voor Schone Kunsten in Arendonk (België), richting conceptueel. Daar heb ik met verschillende materialen geëxperimenteerd zoals papier, hout, klei, acryl,aquarel, gouache, bijenwas. Bijenwas werd mijn grootste passie en heb ik toegepast in verschillende opdrachten. In 2017 heb ik mijn diploma gehaald.</p>
        <p className="mb-2">Vervolgens ben ik verder gaan experimenteren in het werken met bijenwas. In de zomermaanden verblijf en werk ik in Griekenland, met uitzicht op de Egeïsche zee, waar ik me door laat inspireren, ‘daar waar zee en lucht elkaar raken’. Het water van de zee kan glanzen als een spiegel. Dat kan bijenwas ook oproepen. De gelaagdheid en transparantie van was is mijn uitdaging.</p>
        <p>In de winter woon en werk ik in ’s-Hertogenbosch met verschillende media, maak collages, schilder portretten en teken naar life modellen. Mijn werk is zowel figuratief als abstract.</p>
      </div>
    </div>
  )
}