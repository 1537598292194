import { useEffect } from "react";
import CarouselComponent from "../components/CarrouselComponent";
import { addSeries } from "../firebase-actions";

export default function HomePage() {

  // useEffect(() => {
  //   const add = async () => {
  //     await addSeries({
  //       id: "zee",
  //       title: "Zee",
  //       description: "Allemaal zee",
  //       images: []
  //     })
  //   }
  //   // add();
  // },[])

  return (
    <div className="flex flex-col gap-6">
      <div className="font-gray-500 font-semibold">
        <p className="mb-2">Welkom op mijn website!</p>
        <p className="mb-2">Mijn naam is Ans Christiaens. Kijk rustig rond op deze website om mijn werk te bekijken.</p>
      </div>
      <div className="p-4 font-gray-500 font-semibold bg-slate-100 rounded flex flex-col sm:flex-row gap-6">
        <div className="max-w-[100px] flex flex-col justify-center"><img src="rvdk.png" alt="rvdk" className="" /></div>
        <div className="flex-1">
          <p className="mb-2">Tijdens de Pinksterdagen, 19 en 20 mei, vindt de Route van de Kunst 2024, 16 e editie, plaats
            in de dorpen Haarsteeg, Vlijmen, Nieuwkuijk en Drunen. Mijn werk is te zien op een
            gastlocatie, Het Stuurhuis, Kerkstraat 19B, Nieuwkuijk.</p>
          <p className="mb-2">De route is 2 volle dagen te bezoeken van 11.00 tot 17.00 uur.</p>
          <p className="mb-2">Vanaf 18 april tot 17 mei is er een overzichtstentoonstelling van de kunstenaars die
            deelnemen aan de route van de kunst in de Voorste Venne, Anton Pieckplein 71, Drunen.</p>
          <p><a href="https://routevandekunst.nl/" target="_blank" className="text-blue-600">https://routevandekunst.nl/</a></p>
        </div>
      </div>
      <div className="flex">
        <CarouselComponent />
      </div>      
    </div>
  )
}