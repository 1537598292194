import { series } from "../data";

export default function WorkPage() {

  const openDetail = (id: string) => {
    window.location.href = `/series/${id}`
  }

  return (
    <div className="flex flex-col gap-4">
      {series.map(serie => <div className="bg-gray-50 shadow-xl flex flex-col sm:flex-row gap-2 rounded-xl overflow-hidden">
        <div className="rounded-xl sm:rounded-r-none h-full w-[300px]"><img src={`${serie.id}/${serie.images[1]}.JPG`} alt={serie.title}  /></div>
        <div className="card-body flex-1">
          <h2 className="card-title">{serie.title}</h2>
          <p>{serie.description}</p>
          <div className="card-actions justify-end">
            <button className="btn bg-indigo-500 text-white" onClick={() => openDetail(serie.id)}>Bekijk</button>
          </div>
        </div>
      </div>)}
    </div>
  )
}