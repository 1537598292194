export default function ContactPage() {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2 text-xl text-gray-700 font-semibold">
        <div>Mail: <a href="mailto:ans1.christiaens@gmail.com" className="text-indigo-600">ans1.christiaens@gmail.com</a></div>
        <div>Instagram: <a href="https://www.instagram.com/anschristiaens/"  className="text-indigo-600">Ans Christiaens</a></div>
      </div>
      <div className="h-[150px] w-[150px]"><img src="/exlibris.jpg" alt="ex libris" /></div>
    </div>
  )
}